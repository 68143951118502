import React from 'react';
import { Route, Switch } from 'react-router-dom';

import asyncComponent from './components/async.component';
import Classic from './layouts/layout-classic/layout-classic.component';
import Compact from './layouts/layout-compact/layout-compact.component';
import Toolbar from './layouts/layout-toolbar/layout-toolbar.component';
import Boxed from './layouts/layout-boxed/layout-boxed.component';
import Funky from './layouts/layout-funky/layout-funky.component';
import Tabbed from './layouts/layout-tabbed/layout-tabbed.component';
import FifteenBet from './layouts/layout-fifteenbet/layout-fifteenbet.component';
import JoaBet from './layouts/layout-joabet/layout-joabet.component';

import {
  isLogin,
} from './utils/helpers/common';

// import LandingScreen from './containers/landing/landing.component';

/*eslint-disable */

// USERS ROUTE
const AsyncHome = asyncComponent(() => import('./containers/home'));
const AsyncWallet = asyncComponent(() => import('./containers/wallet'));
const AsyncDeposit = asyncComponent(() => import('./containers/wallet/sub-screens/deposit'));
const AsyncDepositFailed = asyncComponent(() => import('./containers/wallet/sub-screens/deposit/failed'));
const AsyncRegister = asyncComponent(() => import('./containers/authentication/register'));
const AsyncWelcomePage = asyncComponent(() => import('./containers/welcome-page'));
const AsyncWithdrawal = asyncComponent(() => import('./containers/wallet/sub-screens/withdrawal'));
const AsyncWithdrawalFailed = asyncComponent(() => import('./containers/wallet/sub-screens/withdrawal/failed'));
const AsyncBankDetails = asyncComponent(() => import('./containers/my-account/sub-screens/bank-details'));
const AsyncCMS = asyncComponent(() => import('./containers/cms'));
const AsynFAQ = asyncComponent(() => import('./containers/faq'));
const AsynSlotBettingRules = asyncComponent(() => import('./containers/slot-betting-rules'));
const AsynLiveCasinoBettingRules = asyncComponent(() => import('./containers/live-casino-betting-rules'));
const AsynDailyRebate = asyncComponent(() => import('./containers/daily-rebate'));
const AsynWeeklyCashback = asyncComponent(() => import('./containers/weekly-cashback'));
const AsynCashOut = asyncComponent(() => import('./containers/cashout'));
const AsyncSystemBetting = asyncComponent(() => import('./containers/system-betting'));
const AsyncFixMobileSafariError = asyncComponent(() => import('./containers/fix-mobile-safari-error'));
const AsynMemorialBonus = asyncComponent(() => import('./containers/memorial-bonus'));
const AsynPrivacyPolicy = asyncComponent(() => import('./containers/privacy-policy'));
const AsynLiveBettingRules = asyncComponent(() => import('./containers/live-betting-rules'));
const AsyncPreMatchGameRules = asyncComponent(() => import('./containers/pre-match-game-rules'));
const AsyncSportsBettingGameRules = asyncComponent(() => import('./containers/sports-betting-game-rules'));
const AsyncAccountDetails = asyncComponent(() => import('./containers/my-account/sub-screens/account-details'));
const AsyncChangePassword = asyncComponent(() => import('./containers/my-account/sub-screens/change-password'));
const AsyncLiveCasino = asyncComponent(() => import('./containers/live-casino'));
const AsyncCasinoGame = asyncComponent(() => import('./containers/live-casino/sub-screens/game'));
const AsyncSlotGames = asyncComponent(() => import('./containers/slot-games'));
const AsyncSlotGamesPlay = asyncComponent(() => import('./containers/slot-games/sub-screens/game'));
const AsyncMiniGames = asyncComponent(() => import('./containers/mini-games'));
const AsyncMyAccount = asyncComponent(() => import('./containers/my-account'));
const AsyncSupport = asyncComponent(() => import('./containers/support'));
const AsyncContactUs = asyncComponent(() => import('./containers/contact-us'));
const AsyncPartners = asyncComponent(() => import('./containers/partners'));
const AsyncPartnersRegister = asyncComponent(() => import('./containers/partners/register'));
const AsyncDownloadCenter = asyncComponent(() => import('./containers/download-center'));
const AsyncPromotions = asyncComponent(() => import('./containers/promotions'));
const AsyncPromotionDetail = asyncComponent(() => import('./containers/promotion-detail'));
// const AsyncVipClub = asyncComponent(() => import('./containers/vip-club'));
const AsyncMessages = asyncComponent(() => import('./containers/my-account/sub-screens/messages'));
const AsyncTransactionHistory = asyncComponent(() => import('./containers/my-account/sub-screens/transaction-history'));
const AsyncBonusCoupon = asyncComponent(() => import('./containers/my-account/sub-screens/bonus-coupon'));
const AsyncRebate = asyncComponent(() => import('./containers/my-account/sub-screens/rebate'));
const AsyncRebateSuccess = asyncComponent(() => import('./containers/my-account/sub-screens/rebate/success'));
const AsyncTransfer = asyncComponent(() => import('./containers/wallet/sub-screens/transfer'));
const AsyncTransferSuccess = asyncComponent(() => import('./containers/wallet/sub-screens/transfer/success'));
const AsyncFavourites = asyncComponent(() => import('./containers/favourites'));
const AsyncBankInfo = asyncComponent(() => import('./containers/support/sub-screens/bank-information'));
const AsyncBetRadarSports = asyncComponent(() => import('./containers/betradar-sports'));
const AsyncOWSports = asyncComponent(() => import('./containers/ow-sports'));
const AsyncCloseA1 = asyncComponent(() => import('./components/custom/close-a1'));
const AsyncBTISports = asyncComponent(() => import('./containers/bti-prod'));
const AsyncESports = asyncComponent(() => import('./containers/e-sports'));
const AsyncArcade = asyncComponent(() => import('./containers/arcade'));
const AsyncArcadePlay = asyncComponent(() => import('./containers/arcade/sub-screens/game'));
const AsyncVirtualSports = asyncComponent(() => import('./containers/virtual-sports'));
const AsyncVirtualSportsPlay = asyncComponent(() => import('./containers/virtual-sports/sub-screens/game'));
const AsyncP2p = asyncComponent(() => import('./containers/p2p'));
const AsyncP2pPlay = asyncComponent(() => import('./containers/p2p/sub-screens/game'));
const AsyncFishing = asyncComponent(() => import('./containers/fishing'));
const AsyncFishingPlay = asyncComponent(() => import('./containers/fishing/sub-screens/game'));
const AsyncWelcome = asyncComponent(() => import('./containers/welcome'));
const AsyncUnauthorized = asyncComponent(() => import('./containers/unauthorized'));
const AsyncPowerball = asyncComponent(() => import('./containers/powerball'));
const AsyncDirectLink = asyncComponent(() => import('./containers/direct-link'));
const AsyncNotice = asyncComponent(() => import('./containers/my-account/sub-screens/notice'));
const AsyncTronExchange = asyncComponent(() => import('./containers/wallet/sub-screens/deposit/tron'));
const AsyncTronRecharge = asyncComponent(() => import('./containers/wallet/sub-screens/deposit/tron-recharge'));
const AsyncBTISportsExclusiveEvent = asyncComponent(() => import('./containers/bti-sports/ExclusiveEvent'));
const AsyncResponsibleGaming = asyncComponent(() => import('./containers/responsible-gaming'));
const AsyncBrowserRestriction = asyncComponent(() => import('./containers/browser-restriction'));
const AsyncRebateTransfer = asyncComponent(() => import('./containers/rebate-transfer'));
const AsyncMiniGame = asyncComponent(() => import('./containers/minigame'));
const AsyncBetConstruct = asyncComponent(() => import('./containers/betconstruct'));
const AsyncRules = asyncComponent(() => import('./containers/rules'));
const AsyncGame = asyncComponent(() => import('./containers/game'));

// AUTHENTICATION ROUTES
const AsyncLogin = asyncComponent(() => import('./containers/authentication/login'));
/* const AsyncRegister = asyncComponent(() => import('./containers/authentication/register/register.component'));
const AsyncProfile = asyncComponent(() => import('./containers/authentication/profile/profile.component'));
const AsyncLock = asyncComponent(() => import('./containers/authentication/lock/lock.component'));
const AsyncForgot = asyncComponent(() => import('./containers/authentication/forgot-password/forgot-password.component')); */

// ERROR ROUTES
// const AsyncError404 = asyncComponent(() => import('./containers/errors/404.component'));
// const AsyncError500 = asyncComponent(() => import('./containers/errors/500.component'));

const AsyncNotFound = asyncComponent(() => import('./containers/not-found'));

const AppRoute = ({
  component: Component,
  layout: Layout,
  noHeader,
  noSidenav,
  noFooter,
  title,
  includeBack,
  hasFavourites,
  hasFilter,
  hasReload,
  backTo,
  ...rest
}) => (
    <Route
      {...rest}
      render={props => (
        <Layout>
          <Component
            {...props}
            noHeader={noHeader}
            noSidenav={noSidenav}
            noFooter={noFooter}
            title={title}
            includeBack={includeBack}
            hasFavourites={hasFavourites}
            hasFilter={hasFilter}
            hasReload={hasReload}
            backTo={backTo}
          />
        </Layout>
      )}
    />
  );

const ClassicLayout = props => (
  <Classic>{props.children}</Classic>
);

const CompactLayout = props => (
  <Compact>{props.children}</Compact>
);

const ToolbarLayout = props => (
  <Toolbar>{props.children}</Toolbar>
);

const BoxedLayout = props => (
  <Boxed>{props.children}</Boxed>
);

const FunkyLayout = props => (
  <Funky>{props.children}</Funky>
);

const TabbedLayout = props => (
  <Tabbed>{props.children}</Tabbed>
);

const FifteenBetLayout = props => (
  <FifteenBet>{props.children}</FifteenBet>
);

const JoaBetLayout = props => (
  <JoaBet>{props.children}</JoaBet>
);

// TODO: Consider looping through an object containing all routes
export default ({ childProps, layout }) => {
  let activeLayout;
  switch (layout.currentLayout) {
    case 'classic':
      activeLayout = ClassicLayout;
      break;
    case 'compact':
      activeLayout = CompactLayout;
      break;
    case 'toolbar':
      activeLayout = ToolbarLayout;
      break;
    case 'boxed':
      activeLayout = BoxedLayout;
      break;
    case 'funky':
      activeLayout = FunkyLayout;
      break;
    case 'tabbed':
      activeLayout = TabbedLayout;
      break;
    // case 'fifteenbet':
    //   activeLayout = FifteenBetLayout;
    //   break;
    case 'joabet':
      activeLayout = JoaBetLayout;
        break;
    default:
      activeLayout = FifteenBetLayout;
  }
  return (
    <Switch>
      <AppRoute path="/login" exact component={AsyncLogin} includeBack props={childProps} layout={activeLayout} noFooter />
      {/* <AppRoute path="/" exact noFooter={!isLogin()} component={AsyncHome} props={childProps} layout={activeLayout} /> */}
      <AppRoute path="/" exact component={AsyncHome} props={childProps} layout={activeLayout} />
      <AppRoute path="/wallet" exact hasReload component={AsyncWallet} title="wallet" props={childProps} layout={activeLayout} />
      <AppRoute path="/rebate-transfer" component={AsyncRebateTransfer} title="rebate transfer" props={childProps} layout={activeLayout} exact includeBack />
      <AppRoute path="/terms-and-conditions" exact includeBack title="terms and conditions" component={AsyncCMS} props={childProps} layout={activeLayout} noFooter />
      <AppRoute path="/frequently-asked-questions" exact component={AsynFAQ} includeBack title="faqs" props={childProps} layout={activeLayout} />
      <AppRoute path="/slots-betting-rules" exact component={AsynSlotBettingRules} includeBack title="" props={childProps} layout={activeLayout} />
      <AppRoute path="/live-casino-betting-rules" exact component={AsynLiveCasinoBettingRules} includeBack title="" props={childProps} layout={activeLayout} />
      <AppRoute path="/reward/daily-rebate" exact component={AsynDailyRebate} includeBack title="daily rebate" props={childProps} layout={activeLayout} />
      {/* <AppRoute path="/reward/weekly-cashback" exact component={AsynWeeklyCashback} includeBack title="weekly cashback" props={childProps} layout={activeLayout} />
      <AppRoute path="/cashout" exact noFooter component={AsynCashOut} includeBack title="캐쉬아웃 가이드" props={childProps} layout={activeLayout} /> */}
      <AppRoute path="/system-betting" exact noFooter component={AsyncSystemBetting} includeBack title="시스템 베팅방법" props={childProps} layout={activeLayout} />
      {/* <AppRoute path="/reward/birthday-bonus" exact component={AsynMemorialBonus} includeBack title="memorial bonus" props={childProps} layout={activeLayout} /> */}
      <AppRoute path="/fix-mobile-safari-error" exact noFooter component={AsyncFixMobileSafariError} includeBack title="" props={childProps} layout={activeLayout} />
      <AppRoute path="/privacy-policy" exact component={AsynPrivacyPolicy} includeBack title="privacy policy" props={childProps} layout={activeLayout} />
      <AppRoute path="/responsible-gaming" exact component={AsyncResponsibleGaming} includeBack title="responsible gaming" props={childProps} layout={activeLayout} noFooter />
      <AppRoute path="/browser-restriction" component={AsyncBrowserRestriction} layout={activeLayout} noHeader noFooter />
      <AppRoute path="/live-betting-game-rules"  exact component={AsyncRules} includeBack title="live betting game rules" props={childProps} layout={activeLayout} />
      <AppRoute path="/prematch-betting-game-rules"  exact component={AsyncRules} includeBack title="pre-match game rules" props={childProps} layout={activeLayout} />
      <AppRoute path="/sports-betting-game-rules" exact component={AsyncSportsBettingGameRules} includeBack title="rules" props={childProps} layout={activeLayout} />
      <AppRoute path="/withdrawal" exact component={AsyncWithdrawal} includeBack title="withdrawal" noFooter props={childProps} layout={activeLayout} />
      <AppRoute path="/withdrawal/failed" exact component={AsyncWithdrawalFailed} title="withdrawal" includeBack noFooter props={childProps} layout={activeLayout} />
      <AppRoute path="/deposit/:promotionID?" exact component={AsyncDeposit} includeBack title="deposit" noFooter props={childProps} layout={activeLayout} />
      <AppRoute path="/tron" exact noHeader noSidenav component={AsyncTronExchange} title="tron" noFooter props={childProps} layout={activeLayout} />
      <AppRoute path="/tron-recharge" exact noHeader noSidenav component={AsyncTronRecharge} title="tron" noFooter props={childProps} layout={activeLayout} />
      <AppRoute path="/deposit/page/failed" exact component={AsyncDepositFailed} title="deposit" includeBack noFooter props={childProps} layout={activeLayout} />
      <AppRoute path="/register" exact component={AsyncRegister} includeBack props={childProps} layout={activeLayout} />
      <AppRoute path="/welcome-page" exact component={AsyncWelcomePage} props={childProps} layout={activeLayout} />
      <AppRoute path="/bank-details" exact component={AsyncBankDetails} includeBack title="bank details" noFooter props={childProps} layout={activeLayout} />
      <AppRoute path="/account-details" exact component={AsyncAccountDetails} includeBack title="my account details" noFooter props={childProps} layout={activeLayout} />
      <AppRoute path="/change-password" exact noFooter component={AsyncChangePassword} includeBack title="change password" props={childProps} layout={activeLayout} />
      {/* <AppRoute path="/slot-games/:provider?" hasFavourites exact component={AsyncSlotGames} props={childProps} layout={activeLayout} />
      <AppRoute path="/slot-games/play/:hashid?"  noHeader noSidenav noFooter exact component={AsyncSlotGamesPlay} props={childProps} layout={activeLayout} />
      <AppRoute path="/mini-games" hasFavourites exact component={AsyncMiniGames} props={childProps} layout={activeLayout} /> */}
      <AppRoute path="/account" exact component={AsyncMyAccount} title="account" props={childProps} layout={activeLayout} />
      <AppRoute path="/support" exact includeBack component={AsyncSupport} title="support" props={childProps} layout={activeLayout} />
      <AppRoute path="/contact-us" exact includeBack component={AsyncContactUs} title="contact us" props={childProps} layout={activeLayout} />
      <AppRoute path="/partners/register" exact includeBack component={AsyncPartnersRegister} props={childProps} layout={activeLayout} />
      <AppRoute path="/partners" exact backTo="/" component={AsyncPartners} includeBack title="partners" props={childProps} layout={activeLayout} noFooter />
      <AppRoute path="/mobile/:provider?" exact component={AsyncDownloadCenter} title="download center" props={childProps} layout={activeLayout} />
      <AppRoute path="/promotions" exact includeBack backTo="/" component={AsyncPromotions} title="promotions" props={childProps} layout={activeLayout} />
      <AppRoute path="/promotions/rebates" exact includeBack backTo="/" component={AsyncPromotions} title="promotions" props={childProps} layout={activeLayout} />
      <AppRoute path="/promotions/bonus" exact includeBack backTo="/" component={AsyncPromotions} title="promotions" props={childProps} layout={activeLayout} />
      <AppRoute path="/promotions/:id" exact  noFooter component={AsyncPromotionDetail} title="promotions" props={childProps} layout={activeLayout} />
      <AppRoute path="/asian-handicap" exact includeBack noFooter component={AsyncDirectLink} title="" props={childProps} layout={activeLayout} />
      <AppRoute path="/calculate-odds" exact includeBack noFooter component={AsyncDirectLink} title="" props={childProps} layout={activeLayout} />
      {/* <AppRoute path="/vip-club" exact component={AsyncVipClub} props={childProps} layout={activeLayout} noFooter /> */}
      <AppRoute path="/message" exact component={AsyncMessages} includeBack title="messages" noFooter props={childProps} layout={activeLayout} />
      <AppRoute path="/notice" exact component={AsyncNotice} includeBack title="notices" noFooter props={childProps} layout={activeLayout} />
      <AppRoute path="/history" exact component={AsyncTransactionHistory} hasFilter includeBack title="transaction history" noFooter props={childProps} layout={activeLayout} />
      <AppRoute path="/bonus" exact component={AsyncBonusCoupon} hasFilter includeBack title="bonus / coupon / cash" noFooter props={childProps} layout={activeLayout} />
      {/* <AppRoute path="/rebate" exact component={AsyncRebate} hasFilter includeBack title="rebate" noFooter props={childProps} layout={activeLayout} />
      <AppRoute path="/rebate/success" exact component={AsyncRebateSuccess} includeBack backTo="/wallet" noFooter props={childProps} layout={activeLayout} /> */}
      {/* <AppRoute path="/transfer" exact component={AsyncTransfer} includeBack title="transfer" noFooter props={childProps} layout={activeLayout} /> */}
      {/* <AppRoute path="/transfer/success" exact component={AsyncTransferSuccess} includeBack backTo="/wallet" noFooter props={childProps} layout={activeLayout} /> */}
      <AppRoute path="/favourites" exact component={AsyncFavourites} hasFilter includeBack noFooter title="favourites" props={childProps} layout={activeLayout} />
      <AppRoute path="/bank-information" exact component={AsyncBankInfo} includeBack title="my banking options" props={childProps} layout={activeLayout} />
      {/* <AppRoute path="/live-sports" exact noHeader noSidenav noFooter includeBack title="" component={AsyncBetRadarSports} props={childProps} layout={activeLayout} /> */}
      <AppRoute path="/live-sports" exact noFooter component={AsyncBetRadarSports} props={childProps} layout={activeLayout} />
      <AppRoute path="/ow-sports" exact noHeader noSidenav noFooter component={AsyncOWSports} props={childProps} layout={activeLayout} />
      {/* <AppRoute path="/15-sports" exact noHeader noSidenav noFooter component={AsyncBTISports} props={childProps} layout={activeLayout} /> */}
      <AppRoute path="/close-a1/:fake_file?" exact noHeader noSidenav noFooter component={AsyncCloseA1} props={childProps} layout={activeLayout} />

      {/* <AppRoute path="/e-sports" exact noFooter component={AsyncESports} props={childProps} layout={activeLayout} /> */}
      <AppRoute path="/minigame/cockfighting" exact noFooter component={AsyncMiniGame} props={childProps} layout={activeLayout} />
      <AppRoute path="/betconstruct" exact noFooter component={AsyncBetConstruct} props={childProps} layout={activeLayout} />
      <AppRoute path="/live-vegas" exact noFooter component={AsyncGame} props={childProps} layout={activeLayout} />

      {/* <AppRoute path="/arcade/:provider?" hasFavourites exact component={AsyncArcade} props={childProps} layout={activeLayout} />
      <AppRoute path="/arcade/play/:hashid?"  noHeader noSidenav noFooter exact component={AsyncArcadePlay} props={childProps} layout={activeLayout} />
      <AppRoute path="/virtual-sports/:provider?" hasFavourites exact component={AsyncVirtualSports} props={childProps} layout={activeLayout} />
      <AppRoute path="/virtual-sports/play/:hashid?"  noHeader noSidenav noFooter exact component={AsyncVirtualSportsPlay} props={childProps} layout={activeLayout} />
      <AppRoute path="/p2p/:provider?" hasFavourites exact component={AsyncP2p} props={childProps} layout={activeLayout} />
      <AppRoute path="/p2p/play/:hashid?"  noHeader noSidenav noFooter exact component={AsyncP2pPlay} props={childProps} layout={activeLayout} />
      <AppRoute path="/fish-hunter/:provider?" hasFavourites exact component={AsyncFishing} props={childProps} layout={activeLayout} />
      <AppRoute path="/fish-hunter/play/:hashid?"  noHeader noSidenav noFooter exact component={AsyncFishingPlay} props={childProps} layout={activeLayout} /> */}
      <AppRoute path="/welcome" noHeader noSidenav noFooter exact component={AsyncWelcome} props={childProps} layout={activeLayout} />
      <AppRoute path="/unauthorized" noHeader noSidenav noFooter exact component={AsyncUnauthorized} props={childProps} layout={activeLayout} />
      {/* <AppRoute path="/powerball" exact noFooter component={AsyncPowerball} props={childProps} layout={activeLayout} /> */}
      <AppRoute path="/slots/:providerID?" noFooter exact component={AsyncSlotGames} props={childProps} layout={activeLayout} />
      <AppRoute path="/slots/:productid?/:gameid?" noHeader noFooter noSidenav exact component={AsyncSlotGamesPlay} props={childProps} layout={activeLayout} />
      <AppRoute path="/live-casino/:providerID?" noFooter exact component={AsyncLiveCasino} props={childProps} layout={activeLayout} />
      <AppRoute path="/live-casino/play/:gameid?" noHeader noFooter noSidenav exact component={AsyncCasinoGame} props={childProps} layout={activeLayout} />
      <AppRoute path="/bti-sports-exclusive-event-mobile" exact includeBack backTo="/promotions" component={AsyncBTISportsExclusiveEvent} title="bti exclusive event" props={childProps} layout={activeLayout} />
      
      <AppRoute path="/betradar-rules" exact includeBack component={AsyncRules} title='betradar rules' props={childProps} layout={activeLayout} />
      <AppRoute path="/betconstruct-rules" exact includeBack component={AsyncRules} title='betconstruct rules' props={childProps} layout={activeLayout} />
      {/* <Route path="/" exact render={props => <LandingScreen {...props}/> } /> */}
      {/*<AppRoute path="/register" exact component={AsyncRegister} props={childProps} layout={NoLayout} />
      <AppRoute path="/profile" exact component={AsyncProfile} props={childProps} layout={activeLayout} />
      <AppRoute path="/lock" exact component={AsyncLock} props={childProps} layout={NoLayout} />
      <AppRoute path="/forgot-password" exact component={AsyncForgot} props={childProps} layout={NoLayout} />
      <AppRoute path="/errors/404" exact component={AsyncError404} props={childProps} layout={NoLayout} />
      <AppRoute path="/errors/500" exact component={AsyncError500} props={childProps} layout={NoLayout} />
      <AppRoute path="/pages/typography" exact component={AsyncTypography} props={childProps} layout={activeLayout} />
      <AppRoute path="/pages/colors" exact component={AsyncColors} props={childProps} layout={activeLayout} /> */}

      <AppRoute path="/errors/404" component={AsyncNotFound} noFooter noHeader layout={activeLayout} />
      <AppRoute component={AsyncNotFound} noFooter noHeader layout={activeLayout} />
    </Switch>);
};
